<template>
	<div class="config">
		<van-form @submit="onSubmit" class="pass-form">
			<van-field label="密钥" label-width="5rem" size="large" clearable v-model="priKey" name="priKey"
				placeholder="请输入密钥" :rules="[{ required:true, message: '请输入密钥' }]" />
			<van-field label="对称密钥" size="large" label-width="5rem" clearable v-model="sysKey" name="sysKey"
				placeholder="请输入对称密钥" :rules="[{ required:true, message: '请输入对称密钥' }]" />
			<div class="debugs">
				<van-radio-group v-model="checked" direction="horizontal" @click="checkeds">
					<van-radio name="true">true</van-radio>
					<van-radio name="false">false</van-radio>
				</van-radio-group>
			</div>
			<van-field label="商户ID" size="large" label-width="5rem" clearable v-model="commID" name="commID"
				placeholder="请输入商户ID" :rules="[{ required:true, message: '请输入商户ID' }]" />
				<van-field label="门店ID" size="large" label-width="5rem" clearable v-model="shopID" name="shopID"
					placeholder="请输入门店ID" :rules="[{ required:true, message: '请输入门店ID' }]" />
			<div class="certificate">
				<p>签名证书路径</p>
				<van-uploader name="image" :before-delete="afterDeletes" :after-read="afterReads" multiple
					:max-count="1" v-model="image" />
				<!-- <van-uploader :after-read="afterRead" /> -->

			</div>

			<van-field label="证书密码" size="large" label-width="5rem" clearable v-model="cerPsw" name="cerPsw"
				placeholder="请输入签名证书密码" :rules="[{ required:true, message: '请输入签名证书密码' }]" />
			<!-- <div class="encyCertificate">
				<p>敏感信息加密证书路径</p>
				<van-uploader>
					<van-button icon="plus" type="primary">上传文件</van-button>
				</van-uploader>
			</div> -->

			<div style="margin-top:2rem;">
				<van-button round block type="primary" native-type="submit">提交</van-button>
			</div>
		</van-form>
	</div>
</template>

<script>
	import {
		ref
	} from 'vue';
	import {
		editconfigs,
		storepresentconfigs,
		uploadFile
	} from '@/api/user'
	export default {
		setup() {
			const checked = ref('true');
			return {
				checked,
			};
		},
		data() {
			return {
				priKey: '',//秘钥
				sysKey: '',//对称秘钥
				commID: '',//商户id
				shopID:'',//门店id
				cerPsw: '',//证书密码
				files: [],
			}
		},
		methods: {
			afterReads(file) {
				let files = file.file
				const formData = new FormData();
				formData.append('file', files)
				uploadFile(formData).then((res)=>{
					// console.log(res)
				})
			},
			onSubmit() {
				let params = {}
				params.mid = this.commID
				params.sid = this.shopID
				editconfigs(params).then((res) =>{
					console.log(res)
				})
			},
			edmitLoad() {
				let params = {}
				params.sid = this.$route.query.sid
				params.mid = this.$route.query.mid
				storepresentconfigs(params).then((res) => {
					console.log(res)
				})
			},
			checkeds() {
				console.log(this.checked)
			}
		},

		mounted() {
			if (this.$route.query.sid && this.$route.query.mid) this.edmitLoad()
			this.shopID = this.$route.query.mid
			this.commID = this.$route.query.sid
		}
	}
</script>
<style lang="less" scoped>
	@import "~@/assets/style/preset.less";

	.config {
		.debugs {
			margin: 10px;
		}

		.certificate {
			display: flex;
			margin: 10px 0;
			margin-left: 15px;

			p {
				color: #646566;
				margin: 10px 15px 0 0;
			}
		}

		.encyCertificate {
			display: flex;
			margin: 10px 0;
			margin-left: 15px;

			p {
				color: #646566;
				margin: 10px 15px 0 0;
			}
		}
	}
</style>
